<template>
  <div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="用户昵称">
        <el-input
          size="mini"
          v-model="formInline.nickname"
          placeholder="请输入用户昵称"
        ></el-input>
      </el-form-item>
      <el-form-item label="更新时间">
        <el-date-picker
          v-model="formInline.time"
          size="mini"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="search">查询</el-button>
        <el-button size="mini" type="primary" @click="goBack">返回</el-button>
      </el-form-item>
      <el-form-item>
        <download-excel
          :before-generate="beforeGenerate"
          :data="excelData"
          :fields="headerData"
          worksheet="观看记录"
          name="观看记录"
        >
          <el-button size="mini"
            >导出<i class="el-icon-download el-icon--right"></i
          ></el-button>
        </download-excel>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: '#f8f8f9',
        color: '#515a6e',
        fontSize: '13px',
        textAlign: 'left'
      }"
      :cell-style="{ textAlign: 'left' }"
    >
      <el-table-column prop="date" label="昵称 I 手机">
        <template slot-scope="{ row }">
          <el-image
            style="width: 50px; height: 50px"
            :src="row.avatar"
            :preview-src-list="[row.avatar]"
          >
          </el-image>
          <div>
            <div>昵称：{{ row.nickname }}</div>
            <div>手机：{{ row.phone }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="所属门店">
        <template>{{ name }}</template>
      </el-table-column>
      <el-table-column prop="topicName" label="专题活动名称"> </el-table-column>
      <el-table-column prop="playCompleteTime" label="播放完成时间">
      </el-table-column>
      <el-table-column prop="lastPlayUpdateTime" label="更新时间">
      </el-table-column>
      <el-table-column prop="lastPlayTime" label="播放时长"> </el-table-column>
      <el-table-column prop="completionRate" label="完播率"> </el-table-column>
    </el-table>
    <div class="fy">
      <div></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getUserTopicPageByCurrentStore } from '../../../api/user'
export default {
  data() {
    return {
      excelData: [],
      headerData: {
        昵称: {
          field: 'nickname',
          callback: (value) => {
            return value
          }
        },
        手机号: {
          field: 'phone',
          callback: (value) => {
            return value
          }
        },
        所属门店: {
          field: 'name',
          callback: (value) => {
            return value
          }
        },
        专题活动: {
          field: 'topicName',
          callback: (value) => {
            return value
          }
        },
        所属门店: {
          field: 'name',
          callback: (value) => {
            return value
          }
        },
        播放完成时间: {
          field: 'playCompleteTime',
          callback: (value) => {
            return value
          }
        },
        更新时间: {
          field: 'lastPlayUpdateTime',
          callback: (value) => {
            return value
          }
        },
        播放时长: {
          field: 'lastPlayTime',
          callback: (value) => {
            return value
          }
        },
        完播率: {
          field: 'completionRate',
          callback: (value) => {
            return value
          }
        }
      },
      state: ['未获得', '已获得'],
      name: localStorage.getItem('nickname'),
      tableData: [],
      total: 0,
      size: 20,
      current: 1,
      formInline: {
        nickname: '',
        time: []
      },
      id: ''
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getUserTopicPageByCurrentStore()
  },
  methods: {
    formatSeconds(value) {
      let result = parseInt(value)
      let h =
        Math.floor((result / 3600) % 24) < 10
          ? '0' + Math.floor((result / 3600) % 24)
          : Math.floor((result / 3600) % 24)
      let m =
        Math.floor((result / 60) % 60) < 10
          ? '0' + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60)
      let s =
        Math.floor(result % 60) < 10
          ? '0' + Math.floor(result % 60)
          : Math.floor(result % 60)
      let res = ''
      if (h !== '00') res += `${h}时`
      if (m !== '00') res += `${m}分`
      res += `${s}秒`
      return res
    },
    async beforeGenerate() {
      this.excelData = []
      const { data } = await getUserTopicPageByCurrentStore({
        pageSize: this.total,
        pageNum: 1,
        storeTopicId: this.id,
        nickname: this.formInline.nickname,
        lastPlayUpdateBeginTime: this.formInline.time.length
          ? this.formInline.time[0] + ' 00:00:00'
          : '',
        lastPlayUpdateEndTime: this.formInline.time.length
          ? this.formInline.time[1] + ' 23:59:59'
          : '',
        listType: 0
      })
      console.log(data)
      data.list.forEach((res) => {
        res.name = this.name
        if (res.isPlayComplete == 1) {
          res.completionRate = 100 + '%'
          res.lastPlayTime = this.formatSeconds(res.fileDuration)
        } else {
          res.completionRate =
            ((res.lastPlayTime / res.fileDuration) * 100).toFixed(1) + '%'
          res.lastPlayTime = this.formatSeconds(res.lastPlayTime)
        }
      })
      this.excelData = data.list
    },
    goBack() {
      this.$router.go(-1)
    },
    search() {
      this.current = 1
      this.getUserTopicPageByCurrentStore()
    },
    async getUserTopicPageByCurrentStore() {
      const { data } = await getUserTopicPageByCurrentStore({
        pageSize: this.size,
        pageNum: this.current,
        storeTopicId: this.id,
        nickname: this.formInline.nickname,
        lastPlayUpdateBeginTime: this.formInline.time.length
          ? this.formInline.time[0] + ' 00:00:00'
          : '',
        lastPlayUpdateEndTime: this.formInline.time.length
          ? this.formInline.time[1] + ' 23:59:59'
          : '',
        listType: 0
      })
      console.log(data)
      data.list.forEach((res) => {
        if (res.isPlayComplete == 1) {
          res.completionRate = 100 + '%'
          res.lastPlayTime = this.formatSeconds(res.fileDuration)
        } else {
          res.completionRate =
            ((res.lastPlayTime / res.fileDuration) * 100).toFixed(1) + '%'
          res.lastPlayTime = this.formatSeconds(res.lastPlayTime)
        }
      })
      this.tableData = data.list
      this.total = data.total * 1
    },
    handleSizeChange(val) {
      this.size = val
      this.getUserTopicPageByCurrentStore()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getUserTopicPageByCurrentStore()
    }
  }
}
</script>

<style lang="scss" scoped>
.fy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.zzz {
  span {
    color: #ff6f00;
  }
}
</style>
